import Cookie from 'js-cookie'
const state = {
    username:Cookie.get('username'),
    loginState:0,
    loginToken:''
}
const mutations = {
    setName:(state,data)=>{
        state.username = data;
        Cookie.set('username',data);
    },
    setLoginState(state,data){
        state.loginState = data;
        Cookie.set('loginState',data);
    }
}
export default {
    namespaced:true,
    state,
    mutations
}