<template>
  <div id="app">
    <router-view style="width: 100%;height: 100%;"></router-view>
  </div>
</template>

<script>
import {mapActions, mapMutations} from 'vuex'

export default {
  name: 'app',
  provide(){
    return {
      reload: this.reload
    }
  },
  methods: {
    ...mapActions({}),
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => this.isRouterAlive = true)
    }
  },
  data(){
    return {
      isRouterAlive:true
    }
  },
  created() {

  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

.el-container:first-child {
  height: 100%;
}

.transition-box {
  margin-bottom: 10px;
  width: 200px;
  height: 100px;
  border-radius: 4px;
  background-color: #409EFF;
  text-align: center;
  color: #fff;
  padding: 40px 20px;
  box-sizing: border-box;
  margin-right: 20px;
}

.el-row {
  margin-bottom: 20px;

&
:last-child {
  margin-bottom: 0;
}

}
.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.el-header, .el-footer, .el-aside {
  background-color: #141C23;
  color: #FFFFFF;
  line-height: 60px;
}

.el-menu-item.is-active {
  border-right: 3px solid #ffd04b;
}

.el-table {
  width: 100%;
  margin-bottom: 10px;
}

aside {
  height: calc(100vh - 120px);
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
