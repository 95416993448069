const barList = [
    {
        path:'/home',
        name:'首页'
    },
    {
        name:'二级菜单',
        children:[
            {
                path:'/second/firstpage',
                name:'二级/1'
            },
            {
                path:'/second/secondpage',
                name:'二级/2'
            }
        ]
    },
    {
        name:'订单管理',
        children:[
            {
                path:'/order/orderlist',
                name:'订单列表'
            },
            {
                path:'/order/wuliu',
                name:'物流追踪'
            }
        ]
    }
]

export default barList