import listData from '@/components/navbar'
const state = {
    navList:listData
}
const mutations = {
    setNavBar(state,data){
        state.navList = data;
    }
}
export default{
    state,
    mutations
}