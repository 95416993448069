const Layout = () => import('../pages/layout')
const staticRoutes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        component: () => import('../pages/login')
    },
    {
        path: '/order',
        component: Layout,
        children: [
            {
                path: '',
                meta: {
                    title: '回收订单列表'
                },
                component: () => import('../pages/order/orderlist')
            },
            {
                path: 'orderDetail/:id',
                meta: {
                    title: '回收订单详情'
                },
                component: () => import('../pages/order/orderDetail')
            },
            {
                path: 'statistics',
                meta: {
                    title: '回收订单统计'
                },
                component: () => import('../pages/order/statistics')
            },
            {
                path: 'jzlist',
                meta: {
                    title: '家政订单列表'
                },
                component: () => import('../pages/order/jzlist')
            },
            {
                path: 'jzstatistics',
                meta: {
                    title: '家政订单统计'
                },
                component: () => import('../pages/order/jzstatistics')
            },
        ]
    },
    {
        path: '/member',
        component: Layout,
        children: [
            {
                path: '',
                meta: {
                    title: '会员列表'
                },
                component: () => import('../pages/member/index')
            },
            {
                path: 'transaction/:id',
                meta: {
                    title: '流水记录'
                },
                component: () => import('../pages/member/transaction')
            },
            {
                path: 'recommon',
                meta: {
                    title: '用户推荐统计'
                },
                component: () => import('../pages/member/recommon')
            },
        ]
    },
    {
        path: '/huishouge',
        component: Layout,
        children: [
            {
                path: '',
                meta: {
                    title: '回收哥列表'
                },
                component: () => import('../pages/huishouge/index')
            },
            {
                path: 'add',
                meta: {
                    title: '添加回收哥'
                },
                component: () => import('../pages/huishouge/add')
            },
            {
                path: 'mdfy/:id',
                meta: {
                    title: '修改回收哥'
                },
                component: () => import('../pages/huishouge/add')
            },
            {
                path: 'tixian',
                meta: {
                    title: '提现管理'
                },
                component: () => import('../pages/huishouge/tixian')
            },
            {
                path: 'location',
                meta: {
                    title: '位置'
                },
                component: () => import('../pages/huishouge/location')
            },
            {
                path: 'transaction/:id',
                meta: {
                    title: '流水记录'
                },
                component: () => import('../pages/huishouge/transaction')
            },
            {
                path: 'huishou',
                meta: {
                    title: '回收分类统计'
                },
                component: () => import('../pages/huishouge/huishou')
            },
        ]
    },
    {
        path: '/gonggao',
        component: Layout,
        children: [
            {
                path: '',
                meta: {
                    title: '公告列表'
                },
                component: () => import('../pages/gonggao/index')
            },
            {
                path: 'add',
                meta: {
                    title: '添加公告'
                },
                component: () => import('../pages/gonggao/add')
            },
            {
                path: 'mdfy/:id',
                meta: {
                    title: '修改公告'
                },
                component: () => import('../pages/gonggao/add')
            },
        ]
    },
    {
        path: '/goodstype',
        component: Layout,
        children: [
            {
                path: '',
                meta: {
                    title: '货物类型列表'
                },
                component: () => import('../pages/goodstype/index')
            }
        ]
    },
    {
        path: '/bindwx',
        component: Layout,
        children: [
            {
                path: '',
                meta: {
                    title: '绑定微信'
                },
                component: () => import('../pages/index/index')
            }
        ]
    },
    {
        path: '/goods',
        component: Layout,
        children: [
            {
                path: '',
                meta: {
                    title: '商品列表'
                },
                component: () => import('../pages/goods/index')
            },
            {
                path: 'add',
                meta: {
                    title: '添加商品'
                },
                component: () => import('../pages/goods/add')
            },
        ]
    },
    {
        path: '/goodsorder',
        component: Layout,
        children: [
            {
                path: '',
                meta: {
                    title: '商品订单列表'
                },
                component: () => import('../pages/goodsorder/index')
            },
        ]
    },
    {
        path: '/jiameng',
        component: Layout,
        children: [
            {
                path: '',
                meta: {
                    title: '运营商列表'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/jiameng/index')
            },
            {
                path: 'add',
                meta: {
                    title: '添加运营商'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/jiameng/add')
            },
            {
                path: 'mdfy/:id',
                meta: {
                    title: '修改运营商'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/jiameng/add')
            },
            {
                path: 'orderlist',
                meta: {
                    title: '订单记录'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/jiameng/orderlist')
            },
            {
                path: 'statistics',
                meta: {
                    title: '订单统计'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/jiameng/statistics')
            },
        ]
    },
    {
        path: '/jiazheng',
        component: Layout,
        children: [
            {
                path: '',
                meta: {
                    title: '家政公司列表'
                },
                component: () => import('../pages/jiazheng/index')
            },
            {
                path: 'add',
                meta: {
                    title: '添加家政公司'
                },
                component: () => import('../pages/jiazheng/add')
            },
            {
                path: 'mdfy/:id',
                meta: {
                    title: '修改家政公司'
                },
                component: () => import('../pages/jiazheng/add')
            },
            {
                path: 'tixian',
                meta: {
                    title: '提现管理'
                },
                component: () => import('../pages/jiazheng/tixian')
            },
            {
                path: 'transaction/:id',
                meta: {
                    title: '流水记录'
                },
                component: () => import('../pages/jiazheng/transaction')
            },
            {
                path: 'type',
                meta: {
                    title: '服务项目'
                },
                component: () => import('../pages/jiazheng/type')
            },
        ]
    },
]
export default staticRoutes